import React, { useState } from "react"
import styled from "@emotion/styled"
import { MdInfoOutline, MdArrowForward } from "react-icons/md"
import { navigate } from "gatsby"
import {
  createSite as text,
  importSite as importSiteText,
  ui as uiText,
} from "@modules/locales/default.js"
import { Spacer, InputFieldBlock, Button } from "gatsby-interface"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import Loading from "@modules/ui/components/Loading"
import useInstallationWindow from "@modules/toolkit/closable-window/useInstallationWindow"
import { ZeroGithubInstallations } from "@modules/organization/shared/components/ZeroGithubInstallations"
import InstallationWindowLoader from "@modules/toolkit/closable-window/InstallationWindowLoader"
import { useValidateUniqueSiteName } from "@modules/site/shared/hooks/useValidateUniqueSiteName"
import { StyledForm } from "@modules/ui/components/Primitives"
import { useCreateSite } from "@modules/onboarding/gaFlow/hooks/useCreateSite"
import { useSetShopifyAppIntegration } from "@modules/cms/shared/hooks/useSetShopifyApp"
import {
  getCurrentSourceProviderTeam,
  useRepoForm,
  RepoFormActionType,
} from "@modules/site/create/Import/components/PickRepository.helpers"
import {
  RepoFieldsCard,
  BranchField,
  InstallationField,
  SiteNameField,
} from "./PickRepository.fields"
import { getPathToSelectProvider } from "@modules/site/create/shared/utils"
import { SourceControlProvider, CmsVendor } from "@modules/graphql/types"
import { useOrganizationNameFieldsFragment } from "@modules/organization/Settings/fragments.generated"

import {
  useSourceProviderTeamsForUserQuery,
  useBranchesForSourceRepositoryLazyQuery,
} from "@modules/site/create/Import/queries.generated"

import { interpolateMessage, FormattedMessage } from "@modules/locales"
import { sourceControlProviderLabels } from "@modules/sourceControlProvider"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import {
  ErrorAlert,
  useTriggerErrorAlert,
} from "@modules/ui/components/ErrorAlert"
import { useTracker } from "@modules/analytics"
import { RepositoryField } from "./RepositoryField"

const headerEmphasizeCss = theme => ({
  color: theme.colors.purple[60],
  fontSize: theme.fontSizes[5],
})

const formSectionCss = theme => ({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  flexDirection: "column",
  [theme.mediaQueries.tablet]: {
    flexWrap: "nowrap",
    flexDirection: "row",
  },
})

const FieldWrapper = styled.div`
  flex: ${props => props.size || 1};

  ${props => props.theme.mediaQueries.tablet} {
    margin-left: ${props => (props.withLeftSpacing ? props.theme.space[7] : 0)};
  }
`

const branchSectionTitleCss = theme => ({
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.default,
  color: theme.colors.grey[80],
  marginBottom: theme.space[4],
})

const refreshingInformationCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[60],
})

function NoInstallations({ hasClosedWindow, popInstallationWindow, provider }) {
  const [isOrgLoading, setOrgLoading] = useState(false)

  if (isOrgLoading || hasClosedWindow) {
    const providerLabel = sourceControlProviderLabels[provider]

    return (
      <InstallationWindowLoader
        message={
          hasClosedWindow
            ? interpolateMessage(text.messages.pendingSourceControlAuth, {
                provider: providerLabel,
              })
            : interpolateMessage(text.messages.openedSourceControlAuth, {
                provider: providerLabel,
              })
        }
        onPopInstallationWindow={() =>
          popInstallationWindow("Add it", {
            uiSource: `Repository Selection`,
          })
        }
      />
    )
  }

  return (
    <ZeroGithubInstallations
      setOrgLoading={setOrgLoading}
      popInstallationWindow={popInstallationWindow}
    />
  )
}

const stepHeaderByProvider = {
  [SourceControlProvider.Github]: importSiteText.headers.repositoryStepGitHub,
  [SourceControlProvider.Gitlab]: importSiteText.headers.repositoryStepGitLab,
  [SourceControlProvider.Bitbucket]:
    importSiteText.headers.repositoryStepBitbucket,
}

const sourceTeamFieldLabelByProvider = {
  [SourceControlProvider.Github]:
    importSiteText.labels.sourceProviderTeamFieldGitHub,
  [SourceControlProvider.Gitlab]:
    importSiteText.labels.sourceProviderTeamFieldGitLab,
  [SourceControlProvider.Bitbucket]:
    importSiteText.labels.sourceProviderTeamFieldBitbucket,
}

const DEFAULT_BRANCHES = [`master`, `main`, `default`]

function isDefaultBranch(branch) {
  return DEFAULT_BRANCHES.includes(branch)
}

export function PickRepository({
  organizationId,
  selectRepo,
  provider = SourceControlProvider.Github,
  frameable,
  frameableCmsVendor,
}) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const { data, loading, error, refetch } = useSourceProviderTeamsForUserQuery({
    variables: {
      provider,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })
  const [forceLoading, setForceLoading] = useState(false)
  const [hasClosedWindow, setHasClosedWindow] = useState(false)
  const { popInstallationWindow } = useInstallationWindow(installationId => {
    refetch()

    if (installationId) {
      dispatch({
        type: RepoFormActionType.SelectInstallation,
        payload: installationId,
      })
    }

    setHasClosedWindow(true)
  })

  const {
    setShopifyAppIntegration: setShopifyApp,
  } = useSetShopifyAppIntegration()

  const sourceOrganizations = (data && data.sourceProviderTeamsForUser) || []

  const [createSite, createSiteLoading] = useCreateSite({ setError })
  const [formState, dispatch] = useRepoForm()

  const { data: orgNameData } = useOrganizationNameFieldsFragment(
    organizationId
  )
  const selectedInstallation = getCurrentSourceProviderTeam(
    formState.installation,
    orgNameData?.name,
    sourceOrganizations
  )

  const [loadBranches] = useBranchesForSourceRepositoryLazyQuery({
    onCompleted: data => {
      const branches = data?.branchesForSourceRepository.branches ?? []
      const defaultBranch = branches.find(isDefaultBranch) ?? branches[0]

      dispatch({
        type: RepoFormActionType.InitializeBranch,
        payload: defaultBranch ?? ``,
      })
    },
    fetchPolicy: `cache-and-network`,
  })

  const [
    verifySiteNameUniqueness,
    {
      loading: loadingSiteNameUniqueness,
      error: serverError,
      validationError,
      clearError,
    },
  ] = useValidateUniqueSiteName()
  const { trackAction } = useTracker()

  if ((loading && sourceOrganizations.length === 0) || forceLoading) {
    return (
      <Loading
        delay={1000}
        message={importSiteText.messages.loadingSourceProviderTeams}
        bufferSize="padded"
      />
    )
  }

  if (error) {
    return (
      <NarrowSingleColumn>
        <ErrorAlert>{error.message}</ErrorAlert>
      </NarrowSingleColumn>
    )
  }

  if (sourceOrganizations.length === 0) {
    return (
      <NarrowSingleColumn>
        <NoInstallations
          hasClosedWindow={hasClosedWindow}
          popInstallationWindow={popInstallationWindow}
          provider={provider}
        />
      </NarrowSingleColumn>
    )
  }

  if (createSiteLoading) {
    return (
      <Loading
        message={importSiteText.messages.creatingSite}
        bufferSize="padded"
      />
    )
  }

  const onSubmit = async e => {
    e.preventDefault()

    // verify sitename uniqueness
    const isValid = await verifySiteNameUniqueness({
      name: formState.siteName,
      sourceOrganizationId: selectedInstallation?.id,
      organizationId,
      sourceControlProvider: provider,
    })

    if (isValid) {
      // create a pending site
      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Create Site - Select repo`,
        uiSource: `Site setup wizard`,
      })
      await createSite({
        organizationId,
        provider,
        nameWithOwner: formState.repo?.nameWithOwner,
        directoryPath: formState.directoryPath,
        branch: formState.branch,
        selectedInstallationId: selectedInstallation?.id,
        successAction: frameable
          ? siteId => {
              if (siteId && frameableCmsVendor === CmsVendor.Shopify) {
                setForceLoading(true)
                return setShopifyApp(siteId)
                  .then(() => {
                    setForceLoading(false)
                    selectRepo(siteId)
                  })
                  .catch(error => {
                    console.error(error)
                  })
              }
            }
          : selectRepo,
        publicName: formState.siteName,
      })
    }
  }

  const hasSelectedRepo = !!formState.repo

  const disableSubmitButton =
    !formState.siteName || !formState.repo || !formState.branch

  return (
    <NarrowSingleColumn>
      <WizardStepHeader
        title={
          <FormattedMessage
            message={stepHeaderByProvider[provider]}
            tags={{
              strong: function(content) {
                return <span css={headerEmphasizeCss}>{content}</span>
              },
            }}
          />
        }
      />
      <StyledForm onSubmit={onSubmit} css={{ minHeight: 300 }}>
        {errorAlert && (
          <React.Fragment>
            {errorAlert}
            <Spacer size={9} />
          </React.Fragment>
        )}

        <RepoFieldsCard hasSelectedRepo={hasSelectedRepo}>
          <div css={formSectionCss}>
            <FieldWrapper size={3}>
              <InstallationField
                label={sourceTeamFieldLabelByProvider[provider]}
                sourceOrganizations={sourceOrganizations}
                selectedInstallation={selectedInstallation?.id}
                onSelect={value =>
                  dispatch({
                    type: RepoFormActionType.SelectInstallation,
                    payload: value,
                  })
                }
                popInstallationWindow={popInstallationWindow}
                provider={provider}
              />
              {loading && (
                <p css={refreshingInformationCss}>
                  {importSiteText.messages.refreshingSourceProviderTeams}
                </p>
              )}
            </FieldWrapper>
            <FieldWrapper size={5} withLeftSpacing>
              <RepositoryField
                label={importSiteText.labels.repositoryField}
                sourceOrgId={selectedInstallation?.id}
                sourceProvider={provider}
                isUserTeam={selectedInstallation?.isUserTeam}
                selectedRepositoryId={formState.repo?.id}
                selectedRepositoryName={formState.repo?.name}
                handleRepositoryChange={value => {
                  dispatch({
                    type: RepoFormActionType.SelectRepo,
                    payload: value,
                  })
                  const nameWithOwner = value?.nameWithOwner

                  if (!nameWithOwner) {
                    return
                  }

                  loadBranches({
                    variables: {
                      nameWithOwner,
                      provider,
                    },
                  })
                }}
                setError={setError}
              />
            </FieldWrapper>
          </div>

          <Spacer size={7} />

          {formState.repo && !formState.isBranchInitialized && (
            <Loading
              delay={1000}
              bufferSize="padded"
              message={importSiteText.messages.loadingRepositoryBranches}
            />
          )}

          {formState.repo && formState.isBranchInitialized && (
            <div role="group" aria-labelledby="branchAndPath__title">
              <div id="branchAndPath__title" css={branchSectionTitleCss}>
                {importSiteText.headers.repositorySiteDetails}
              </div>
              <div css={formSectionCss}>
                <FieldWrapper size={4}>
                  <BranchField
                    title={importSiteText.labels.baseBranchField}
                    nameWithOwner={formState.repo?.nameWithOwner}
                    selectedBranch={formState.branch}
                    onSelect={value =>
                      dispatch({
                        type: RepoFormActionType.SelectBranch,
                        payload: value,
                      })
                    }
                    provider={provider}
                  />
                </FieldWrapper>
                <FieldWrapper size={6} withLeftSpacing>
                  <InputFieldBlock
                    id="directoryPath"
                    name="directoryPath"
                    label={importSiteText.labels.baseDirectoryField}
                    hint={
                      <span>
                        <MdInfoOutline />{" "}
                        {importSiteText.messages.baseDirectoryHint}
                      </span>
                    }
                    placeholder="/"
                    value={formState.directoryPath}
                    onChange={e => {
                      dispatch({
                        type: RepoFormActionType.ChangeDirectoryPath,
                        payload: e.target.value,
                      })
                    }}
                  />
                </FieldWrapper>
              </div>
            </div>
          )}

          {formState.repo && formState.isBranchInitialized && (
            <React.Fragment>
              <Spacer size={7} />

              <SiteNameField
                label={importSiteText.labels.siteNameField}
                value={formState.siteName}
                error={validationError || serverError}
                onFocus={() =>
                  dispatch({
                    type: RepoFormActionType.TouchSiteName,
                  })
                }
                onChange={e => {
                  dispatch({
                    type: RepoFormActionType.ChangeSiteName,
                    payload: e.target.value,
                  })
                  clearError()
                }}
              />
            </React.Fragment>
          )}
        </RepoFieldsCard>
        <WizardFooter
          goBackButton={
            <Button
              variant="SECONDARY"
              onClick={() => {
                if (frameable) {
                  return navigate(-1)
                } else {
                  return navigate(getPathToSelectProvider(organizationId))
                }
              }}
            >
              {uiText.actions.back}
            </Button>
          }
          goNextButton={
            <Button
              disabled={disableSubmitButton}
              loading={loadingSiteNameUniqueness}
              rightIcon={<MdArrowForward />}
              type="submit"
            >
              {uiText.actions.next}
            </Button>
          }
        />
      </StyledForm>
    </NarrowSingleColumn>
  )
}
