import {
  useCreateSiteMutation,
  CreateSiteMutationVariables,
} from "@modules/onboarding/gaFlow/queries.generated"

export type UseCreateSiteParams = {
  setError: (e: { message: string }) => void
}

export const useCreateSite = ({ setError }: UseCreateSiteParams) => {
  const [createSiteMutation, { loading }] = useCreateSiteMutation()

  const createSite = ({
    organizationId,
    provider,
    nameWithOwner,
    directoryPath,
    branch,
    selectedInstallationId,
    successAction,
    publicName,
  }: CreateSiteMutationVariables & {
    successAction: (siteId: string) => void
  }) =>
    createSiteMutation({
      variables: {
        organizationId,
        provider,
        nameWithOwner,
        directoryPath,
        branch,
        selectedInstallationId,
        publicName,
      },
    })
      .then(({ data }) => {
        const createSite = data && data.createSite
        const validation = createSite && createSite.validation
        const site = createSite && createSite.site
        const siteId = site && site.id
        const success = validation && validation.success

        if (success) {
          return successAction(siteId)
        }

        return setError(validation as { message: string })
      })
      .catch(err => setError(err))

  return [createSite, loading] as const
}
