import React from "react"
import {
  SelectFieldBlock,
  InputFieldBlock,
  FormFieldBlock,
} from "gatsby-interface"
import { useTracker } from "@modules/analytics"
import { SourceControlProvider } from "@modules/graphql/types"
import { useBranchesForSourceRepositoryQuery } from "@modules/site/create/Import/queries.generated"
import { BranchesCombobox } from "@modules/ui/components/BranchesCombobox"

const emphasizedCardCss = theme => ({
  position: `relative`,
  padding: theme.space[7],
  marginTop: `-${theme.space[7]}`,
  "&:before, &:after": {
    content: '" "',
    position: `absolute`,
    borderRadius: theme.radii[3],
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
  },
  "&:before": {
    backgroundImage: `linear-gradient(110deg, #8954a8 0, #663399 25%, #bc027f 50%, #ffdf37 75%, #05f7f4 100%)`,
    borderRadius: theme.radii[3],
    opacity: 0,
    transition: `opacity ${theme.transitions.default}`,
  },
  "&:after": {
    backgroundColor: theme.colors.white,
    margin: `2px`,
    borderRadius: `6px`,
  },
})

const emphasizedCardActiveCss = _theme => ({
  "&:before": {
    opacity: 1,
  },
})

export function RepoFieldsCard({ hasSelectedRepo = false, ...rest }) {
  return (
    <div
      css={theme => [
        emphasizedCardCss(theme),
        hasSelectedRepo && emphasizedCardActiveCss(theme),
      ]}
      {...rest}
    />
  )
}

const dummyInstallationOption = {
  value: "__none__",
  label: "Add new Organization",
}

export function InstallationField({
  label,
  sourceOrganizations,
  selectedInstallation,
  onSelect,
  popInstallationWindow,
  provider,
}) {
  return (
    <SelectFieldBlock
      id="installationField"
      name="installationField"
      label={label}
      options={sourceOrganizations
        .map(org => ({
          value: org.id,
          label: org.name,
        }))
        .concat(
          provider === SourceControlProvider.Github
            ? [dummyInstallationOption]
            : []
        )}
      onChange={e => {
        if (e.target.value === dummyInstallationOption.value) {
          popInstallationWindow(`Add New Organization`, {
            uiSource: `Repository Selection - Menu`,
          })
        } else {
          onSelect(e.target.value)
        }
      }}
      value={selectedInstallation}
      required
    />
  )
}

export function BranchField({
  nameWithOwner,
  selectedBranch,
  onSelect,
  title,
  provider = SourceControlProvider.Github,
}) {
  const { trackButtonClicked } = useTracker()
  const [searchCriteria, setSearchCriteria] = React.useState("")
  const { data } = useBranchesForSourceRepositoryQuery({
    variables: {
      searchText: searchCriteria,
      nameWithOwner,
      provider,
    },
  })

  return (
    <FormFieldBlock
      id="selectBranch"
      label={title}
      error={!selectedBranch ? `A branch must be selected` : null}
      required
    >
      {controlProps => (
        <BranchesCombobox
          branches={data?.branchesForSourceRepository?.branches || []}
          aria-label={title}
          inputId={controlProps.id}
          aria-describedby={controlProps["aria-describedby"]}
          selectedBranch={selectedBranch}
          onSearchCriteriaChange={setSearchCriteria}
          onBranchChange={branchName => {
            trackButtonClicked(`Branch list`, {
              siteId: undefined,
              uiSource: `Create and setup site`,
            })

            onSelect(branchName)
          }}
        />
      )}
    </FormFieldBlock>
  )
}

export function SiteNameField({
  value,
  onFocus,
  onChange,
  validationError,
  serverError,
  error,
  label,
  layout,
}) {
  const finalError = !value
    ? `Enter a site name`
    : error || serverError || validationError

  return (
    <InputFieldBlock
      id="site-name"
      label={label}
      hint={!finalError && "A unique site name is required"}
      error={finalError}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      layout={layout}
    />
  )
}
